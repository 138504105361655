import { EventSenderClient } from 'services/sender';
import redirect from 'lib/redirect';
import { MUPImpressionEvent } from '@spotify-internal/event-definitions/events/createMUPImpression';
import { MUPImpressionNonAuthEvent } from '@spotify-internal/event-definitions/events/createMUPImpressionNonAuth';
import { MUPActionEvent } from '@spotify-internal/event-definitions/events/createMUPAction';
import { MUPActionNonAuthEvent } from '@spotify-internal/event-definitions/events/createMUPActionNonAuth';

const RESOLVE_TIMEOUT = 1000;

const timeout = (t: number) => {
  return new Promise(resolve => setTimeout(resolve, t));
};
type EventData =
  | MUPActionEvent
  | MUPActionNonAuthEvent
  | MUPImpressionNonAuthEvent
  | MUPImpressionEvent;

export function trackEvent(event: EventData): Promise<unknown> {
  if (typeof window.__DNF_EVENT_LOGGER__ === 'object') {
    try {
      window.__DNF_EVENT_LOGGER__.push(event);
    } catch (e) {
      /**/
    }
  }
  return EventSenderClient.getInstance().send(event, { flush: true });
}

export function trackAndRedirect(
  event: EventData,
  redirectUrl: string,
): Promise<unknown> {
  return Promise.race([timeout(RESOLVE_TIMEOUT), trackEvent(event)]).finally(
    () => {
      redirect(redirectUrl);
    },
  );
}
