import styled from 'styled-components';
import BaseLayout from 'components/Layout/Base';

// @ts-ignore
export const BareLayout = styled(BaseLayout).attrs({ 'aria-live': 'polite' })<{
  darkMode?: boolean;
}>`
  background: ${({ darkMode, theme }) =>
    !!darkMode ? theme.invertedBackground : theme.background};
  color: ${props => props.theme.textColor};
  width: 100%;
`;

export default BareLayout;
