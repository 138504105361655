export const getEnvironmentQueryParameter = (path: string): string | null => {
  const searchParams = new URL(path).searchParams;
  return searchParams.get('e');
};

const defaultUrlUpdateCallback = (path: string) => {
  const isRenderedInIframe = !!getEnvironmentQueryParameter(
    window?.location?.toString(),
  );
  if (isRenderedInIframe) {
    window.parent.location.href = path;
  } else {
    window.location.href = path;
  }
};
const redirect = (
  url: string,
  urlUpdateCallback = defaultUrlUpdateCallback,
): void => {
  urlUpdateCallback(url);
};

export const open = (url: string): void => {
  window.open(url);
};

export const reload = (): void => {
  window.location.reload();
};

export default redirect;
