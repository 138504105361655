import { createBaseTransport, Transport } from '@spotify-internal/transport';
import {
  createProviders,
  createTokenProvider,
} from '@spotify-internal/transport/es5/providers/spotify_com';
import { requestTransformerCreator } from '@spotify-internal/transport/es5/plugins/request_transformer';

const createDevProviders = () => ({
  endpoints: () =>
    Promise.resolve({
      webgate: '/api/',
      webapi: '/api/',
    }),
  token: createTokenProvider(),
});

export class TransportClient {
  private static transport?: Transport;

  public static getInstance(): Transport {
    if (!TransportClient.transport) {
      TransportClient.transport = createBaseTransport({
        providers:
          process.env.NODE_ENV === 'development'
            ? createDevProviders()
            : (createProviders() as any),
      });
      TransportClient.transport.addPlugin(requestTransformerCreator as any, {
        optionsOverrides: {
          authorize: false,
        },
      });

      TransportClient.transport.connect();
    }
    return TransportClient.transport;
  }
}
