import { Translator } from 'lib/i18n';
import { MemberId, Member, MemberAvatarFallback } from 'types';
import { HomeHubData } from 'data/homeHub/useHomeHub';

export const orderMembers = (members: Array<Member>) =>
  members.sort((a: Member) => (a.isLoggedInUser !== true ? 1 : -1));

export const getLoggedInMember = (members: Array<Member>) =>
  members.find((m: Member) => m.isLoggedInUser);

export const getMemberRole = (
  member: Member,
  t: Translator,
  viewedByManager = true,
) => {
  if (member.isMaster) {
    return t('home_hub.members.roles.plan_manager');
  } else if (member.isChildAccount && member.isChildAllowedInMusicApp) {
    if (viewedByManager) {
      return t('managed_plan_member');
    }
    return t('young_listener');
  } else if (member.isChildAccount) {
    return t('home_hub.members.roles.kids_accounts');
  }
  return t('home_hub.members.roles.plan_member');
};

export const getMemberById = (members: Array<Member>, id: MemberId) =>
  members.find((m: Member) => m.id === id);

export const getMemberAvatarFallback = (
  member: Member,
): MemberAvatarFallback => {
  if (member.isChildAccount) {
    if (member.isChildAllowedInMusicApp) return 'GEN_ALPHA';
    return 'KIDS';
  }
  return 'GENERIC';
};

export const getMemberAvatarUrl = (
  member: Member,
  viewedByManager: boolean,
  imageSize?: 'small' | 'large',
): string | null => {
  if (member.isChildAccount) {
    if (member.isChildAllowedInMusicApp && viewedByManager) {
      return member.avatar;
    }
    return null;
  }

  if (imageSize === 'small') return member.smallProfileImageUrl;
  return member.largeProfileImageUrl;
};

export const toggleParentalControlOnMember = (
  home: HomeHubData,
  memberId: MemberId,
): HomeHubData => {
  // deep clone home object without relying on third-party libraries or structuredClone
  const updatedHome: HomeHubData = {
    ...home,
    members: home.members.map(m => ({ ...m })),
  };
  const member = updatedHome.members.find(m => m.id === memberId);
  if (member) {
    member.explicitContentLockEnabled = !member.explicitContentLockEnabled;
  }
  return updatedHome;
};
