import {
  screenLgMin,
  screenMdMin,
  screenSmMin,
  screenXsMin,
} from '@spotify-internal/encore-web';

const createMinWidthMediaQuery = (minWidth: string) =>
  `@media (min-width: ${minWidth})`;

const createMaxWidthMediaQuery = (maxWidth: string) =>
  `@media (max-width: ${maxWidth})`;

export const media = {
  mobileXsDown: createMaxWidthMediaQuery(screenXsMin),
  mobileXsUp: createMinWidthMediaQuery(screenXsMin),
  mobileSmDown: createMaxWidthMediaQuery(screenSmMin),
  mobileSmUp: createMinWidthMediaQuery(screenSmMin),
  mobileMdUp: createMinWidthMediaQuery(screenMdMin),
  desktopLgUp: createMinWidthMediaQuery(screenLgMin),
};
