import React from 'react';
import { Type } from '@spotify-internal/encore-web';
import styled from 'styled-components';
import * as tokens from 'styles';
import { media } from 'styles/mixins';

export const H1 = (props: React.ComponentProps<typeof Type.h1>) => (
  <Type.h1 variant="brio" {...props} />
);
export const H2 = (props: React.ComponentProps<typeof Type.h2>) => (
  <Type.h2 {...props} />
);
export const H3 = (props: React.ComponentProps<typeof Type.h3>) => (
  <Type.h3 {...props} />
);
export const H4 = (props: React.ComponentProps<typeof Type.h4>) => (
  <Type.h4 {...props} />
);

export const P = (props: React.ComponentProps<typeof Type.p>) => (
  <Type.p
    paddingBottom={
      typeof props.paddingBottom === 'string' ? props.paddingBottom : '1em'
    }
    variant="ballad"
    {...props}
  />
);
export const MainHeadline = styled(H1)`
  padding-bottom: ${tokens.spacer12};
  ${media.mobileSmUp} {
    padding-bottom: ${tokens.spacer24};
  }
`;

const exportedTypes = {
  // exports everything from tape's Type
  ...Type,

  // overrides tape's lower-cased components
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  p: P,
  Span: Type.span,

  // following our pattern, exports upper-cased components too
  H1,
  H2,
  H3,
  H4,
  P,
};

export default exportedTypes;
