import {
  createEventSender,
  Environment,
  EventSender,
} from '@spotify-internal/event-sender';

import {
  createApplicationContext,
  createCorrelationIdContext,
  createUserAgentContext,
} from '@spotify-internal/event-sender/es5/contexts';

import Cookie from 'js-cookie';

import { TransportClient } from './transport';

const transport = TransportClient.getInstance();

export class EventSenderClient {
  private static eventSender?: EventSender;

  public static getInstance(): EventSender {
    if (!EventSenderClient.eventSender) {
      EventSenderClient.eventSender = createEventSender<Environment.BROWSER>({
        transport,

        context: [
          createCorrelationIdContext(Cookie.get('sp_usid') ?? 'unknown'),

          createUserAgentContext(window?.navigator?.userAgent ?? 'unknown'),

          createApplicationContext({
            version: process.env.APPLICATION_VERSION ?? 'dev',
          }),
        ],
      });
    }

    return EventSenderClient.eventSender;
  }
}
