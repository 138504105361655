import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { LogoSpotify } from '@spotify-internal/encore-web';
import { media } from 'styles/mixins';
import { BuildUrlProps, buildUrlStrict } from 'lib/url';
import BaseLayout from 'components/Layout/Base';
import { useTranslation } from 'data/translation/useTranslation';
import { usePlan } from 'data/plan/usePlan';

const MainWrapper = styled(BaseLayout)`
  background: ${props => props.theme.background};
  min-height: calc(var(--vh, 100vh));
`;

type ChildrenWrapperProps = {
  $centered: boolean;
};

const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  text-align: ${({ $centered }) => ($centered ? 'center' : 'left')};
  color: ${props => props.theme.textColor};
`;

const Logo = styled(LogoSpotify)<{ $darkMode: boolean }>`
  fill: ${({ $darkMode, theme }) =>
    $darkMode ? theme.invertedLogoColor : theme.logoColor};
  width: 89px;
  height: 27px;
  ${media.mobileMdUp} {
    width: 132px;
    height: 40px;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 15px;
  height: 56px;
  ${media.mobileSmUp} {
    padding: 0 24px;
  }
  ${media.mobileMdUp} {
    max-width: 972px;
    height: 84px;
    padding: 0 15px;
  }
  ${media.desktopLgUp} {
    max-width: 1172px;
    height: 84px;
  }
`;

type LayoutProps = BuildUrlProps & {
  darkMode?: boolean;
  centered?: boolean;
  children: React.ReactNode;
  style?: CSSProperties;
};

const Layout = ({ children, centered, darkMode, ...props }: LayoutProps) => {
  const { planType } = usePlan();
  const { t, locale } = useTranslation();

  return (
    <MainWrapper darkMode={darkMode ?? false} {...props} aria-live="polite">
      <Header>
        <a
          href={buildUrlStrict(planType, 'HOME_PAGE', locale)}
          title={t('go_to_spotify_com')}
        >
          <Logo $darkMode={darkMode ?? false} />
        </a>
      </Header>
      <ChildrenWrapper {...props} $centered={!!centered}>
        {children}
      </ChildrenWrapper>
    </MainWrapper>
  );
};

export default Layout;
