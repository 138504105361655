import React, { useEffect, ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';
import { updateGlobalViewportHeightVar } from 'styles';
import { usePlan } from 'data/plan/usePlan';
import { PLAN_FAMILY } from 'lib/plans';

const Base = styled.main`
  min-height: calc(var(--vh, 100vh));
`;

const BaseLayout = (props: {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  darkMode?: boolean;
  id?: string;
  'data-testid'?: string;
}) => {
  const { planType } = usePlan();
  useEffect(() => {
    window.addEventListener('resize', updateGlobalViewportHeightVar);
    updateGlobalViewportHeightVar();
    return () => {
      window.removeEventListener('resize', updateGlobalViewportHeightVar);
    };
  }, []);
  const themeClass =
    planType === PLAN_FAMILY
      ? 'encore-premium-family-set'
      : 'encore-premium-duo-set';
  return (
    <Base
      style={props.style}
      className={`${props.className} ${themeClass}`}
      id={props.id}
      data-testid={props['data-testid']}
    >
      {props.children}
    </Base>
  );
};

export default BaseLayout;
