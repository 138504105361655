import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { LogoSpotify } from '@spotify-internal/encore-web';
import * as tokens from 'styles';
import { media } from 'styles/mixins';
import Bare from 'components/Layout/Bare';
import { buildUrlStrict } from 'lib/url';
import { useTranslation } from 'data/translation/useTranslation';
import { usePlan } from 'data/plan/usePlan';

type PaddedWrapperProps = {
  $headless?: boolean;
  $condensedHeader?: boolean;
};

const PaddedWrapper = styled(Bare)<
  PaddedWrapperProps & { $withBackground?: boolean }
>`
  padding: 14px ${tokens.spacer24} ${tokens.spacer24} ${tokens.spacer24};
  // on account pages we don't want anything to take 100%
  min-height: 100vh;
  min-height: ${({ $headless }) =>
    $headless ? '200px' : `calc(var(--vh, 100vh));`};
  display: flex;
  flex-direction: column;
  ${media.mobileMdUp} {
    padding-top: ${tokens.spacer20};
  }
  background-color: ${props =>
    props.$withBackground ? 'var(--background-base)' : 'initial'};
`;

type MainWrapperProps = {
  $centered: boolean;
};

const MainWrapper = styled.div<MainWrapperProps>`
  width: 100%;
  text-align: ${({ $centered }) => ($centered ? 'center' : 'left')};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  ${media.mobileSmUp} {
    max-width: 610px;
    margin: 0 auto;
    justify-content: normal;
  }
`;

const Logo = styled(LogoSpotify)<{ $condensed: boolean }>`
  width: 89px;
  height: 27px;
  margin-bottom: ${({ $condensed }) =>
    $condensed ? tokens.spacer8 : tokens.spacer32};

  ${media.mobileXsUp} {
    margin-bottom: ${tokens.spacer32};
  }

  ${media.mobileMdUp} {
    width: 132px;
    height: 40px;
  }
`;

export const Header = styled.header`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 722px;
  ${media.mobileSmUp} {
    max-width: 942px;
  }
  ${media.desktopLgUp} {
    max-width: 1142px;
  }
`;

type LayoutProps = PaddedWrapperProps & {
  centered?: boolean;
  headless?: boolean;
  condensedHeader?: boolean;
  id?: string;
  withBackground?: boolean;
  children?: ReactNode;
  className?: string;
};

const Layout = ({
  children,
  centered = false,
  headless = false,
  condensedHeader = false,
  ...props
}: LayoutProps) => {
  const { t, locale } = useTranslation();
  const { planType } = usePlan();

  return (
    <PaddedWrapper
      {...props}
      className={props.className}
      $withBackground={props.withBackground}
      $headless={headless}
    >
      {!headless && (
        <Header>
          <a
            href={buildUrlStrict(planType, 'HOME_PAGE', locale)}
            title={t('go_to_spotify_com')}
          >
            <Logo
              style={{ color: tokens.black }}
              $condensed={condensedHeader}
            />
          </a>
        </Header>
      )}
      <MainWrapper $centered={centered}>{children}</MainWrapper>
    </PaddedWrapper>
  );
};

export default Layout;
