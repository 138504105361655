import { ButtonPrimary } from '@spotify-internal/encore-web';
import styled from 'styled-components';
import React from 'react';

const StyledPrimary = styled(ButtonPrimary)<{
  $alternateFamily?: boolean;
  $alternateDuo?: boolean;
}>`
  ${({ $alternateFamily }) =>
    $alternateFamily &&
    `
    color: white;
  `}
  cursor: pointer;
`;

export const PrimaryButton = (
  props: React.ComponentProps<typeof StyledPrimary>,
) => {
  let themeClassName = `home-hub-button-primary ${props.className}`;
  if (props.$alternateFamily) {
    themeClassName = `encore-premium-family-alternate-set ${themeClassName}`;
  }
  if (props.$alternateDuo) {
    themeClassName = `encore-premium-duo-alternate-set ${themeClassName}`;
  }
  return <StyledPrimary {...props} className={themeClassName} />;
};
